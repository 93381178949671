import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { ApiService } from 'src/app/services/api.service';
import { TellerService } from 'src/app/services/teller.service';
import { NavComponent } from 'src/app/traits/nav/nav.component';

@Component({
  selector: 'app-add-group',
  templateUrl: './add-group.component.html',
  styleUrls: ['./add-group.component.css']
})
export class AddGroupComponent implements OnInit {
  @ViewChild('groupForm') public any: NgForm;
  grp: any; edit: Boolean; res: any; id: any; accounts: any[] = [];
  // type: any[] = []; cust: any[] = []; users: any[] = [];
  constructor(private api: ApiService, private _nav: Router, private _route: ActivatedRoute, private use: NavComponent) { }

  ngOnInit() {
    this.formReset();
    this.getAll();
  }

  getAll() {
    this.id = this._route.snapshot.paramMap.get('id');
    this.getGrp(this.id);
  }

  getGrp(id: any) {
    if (id && id !== 0) {
      this.edit = true;
      this.api.get('icare/'+id).subscribe((data: any) => {
        this.grp = data; this.accounts = data.account; // this.Selected();
      }, (err: HttpErrorResponse) => {
        this.use.showMsg('error', 'Fetching Fail', 'Unable to Get iCare Details'); });
    } else {
      this.edit = false;
      this.formReset();
    }
  }

  Selected() {
    // this.api.get(this.grp.code).subscribe((data: any) => {
    //   this.grp.customerid = data.customerid;
    //   this.new = data; this.groups = data.groups;
    // }, (err: HttpErrorResponse) => {
    //   this.use.showMsg('error', 'Fetching Fail', 'Unable to Fetch Customer Previous Group');
    // });
  }

  saveGroup() {
    if(this.grp.groupid > 0) {
      this.putGroup();
    } else {
      this.postGroup();
    }
  }

  postGroup() {
    this.api.post('icare',this.grp).subscribe((data: any) => {
      this.res = data; this.formReset(this.any);
      this.use.showMsg('success', 'Group Created', 'Added to list Successfully');
      this._nav.navigate(['groups']);
    }, (err: HttpErrorResponse) => {
      this.use.showMsg('error', 'Creating Fail', 'Unable to Add New iCare');
    });
  }

  putGroup() {
    this.grp.muserid = this.use.active.id; this.grp.mdate = new Date();
    this.api.put('icare/'+this.grp.groupid, this.grp).subscribe((data: any) => {
      this.res = data; this.formReset(this.any);
      this.use.showMsg('success', 'iCare Updated', 'iCare Details Updated Successfully');
      this._nav.navigate(['groups']);
    }, (err: HttpErrorResponse) => {
      this.use.showMsg('error', 'Updating Fail', 'Unable to Update iCare Details');
    });
  }

  formReset(form?: NgForm) {
    if (form != null) {
      form.reset();
    } else {
      this.grp = {
        groupid: 0, code: null, type: '', name: '', group_leader: '', mobile: '', pin: '1234', allow_withdrawal: false,
        group_secretary: '', group_secretary_mobile: '', balance: 0, loan: 0, rate: 0, limit: 1, active: true, status: 'Active', 
        merchantid: this.use.active.merchantid, userid: this.use.active.id, date: this.use.date, muserid: null, mdate: null
      };
    }
  }


}
