import { Component, OnInit } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { NavComponent } from 'src/app/traits/nav/nav.component';
import { ManagerService } from 'src/app/services/manager.service';
import { ApiService } from 'src/app/services/api.service';

@Component({
  selector: 'app-account-autodebit',
  templateUrl: './account-autodebit.component.html',
  styleUrls: ['./account-autodebit.component.css']
})
export class AccountAutodebitComponent implements OnInit {

  accounts: any[] = []; response:any;
  constructor(private use: NavComponent, private api: ApiService) { }

  ngOnInit() {
    this.getAllAccounts();
  }

  getAllAccounts() {
    this.api.get('autodebit').subscribe((data: any) => {
      this.accounts = data.data;
      console.log(data.data);
    }, (err: HttpErrorResponse) => {
      this.use.showMsg('error', 'AutoDebit List Fail', 'Unable to Fetch AutoDebit List');
    });
  }
  
  disabled(val) {
    val.active = false; val.muserid = this.use.active.id; val.mdate = new Date();
    this.api.put(val.accountid,val).subscribe((data: any) => {
      this.use.showMsg('success', 'AutoDebit Approved', 'AutoDebit Has been DeActivated');
    }, (err: HttpErrorResponse) => {
      this.use.showMsg('error', 'AutoDebit List Fail', 'Unable to Fetch AutoDebit List');
    });
  }

}




